/**
 * @param element HTMLElement with aria-controls attribute
 * @usage
 * const toggle = document.querySelector('.toggle')
 * toggle.addEventListener('click', () => {
 *    toggleAriaExpanded(toggle)
 * })
 */
export function toggleAriaExpanded(element: HTMLElement) {
    const elementExpanded = toggleTextBoolean(
        element.getAttribute('aria-expanded') ?? 'false'
    )
    const controlledElement = getControlledElement(element)

    element.setAttribute('aria-expanded', elementExpanded)
    toggleExpanded(controlledElement, elementExpanded)
}

export function toggleExpanded(el: HTMLElement, expanded: 'true' | 'false') {
    el.setAttribute('data-expanded', expanded)
    el.setAttribute('aria-hidden', oppositeTextBoolean(expanded))
}

function getControlledElement(element: HTMLElement) {
    const controlledElementId = element.getAttribute('aria-controls')
    if (controlledElementId === null) {
        throw new Error(
            `aria-controls attribute is missing on element with id ${element}`
        )
    }

    const controlledElement = document.getElementById(controlledElementId)
    if (controlledElement === null) {
        throw new Error(`Element with id ${controlledElementId} does not exist`)
    }

    return controlledElement
}

/**
 * Function that takes in a string version of a boolean and returns the opposite
 * @param text Boolean as a string
 * @returns opposite of the boolean
 */
function toggleTextBoolean(boolean: string) {
    return boolean === 'true' ? 'false' : 'true'
}

function oppositeTextBoolean(boolean: string) {
    return boolean === 'true' ? 'false' : 'true'
}

window.toggleAriaExpanded = toggleAriaExpanded
