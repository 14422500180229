import { HttpClient } from "@utils/http-client";

import type { MeilisearchResponse } from "@utils/types/search";

type MeilisearchConfig = {
    host: string;
    apiKey: string;
    index: string;
};

export default class MeilisearchClient {
    private config: MeilisearchConfig;
    private client: HttpClient;
    private limit: number;

    constructor(config: MeilisearchConfig, limit = 10) {
        if(!config.host || !config.apiKey || !config.index) {
            throw new Error('Invalid Meilisearch config');
        };
        this.config = config;
        this.limit = limit;
        this.client = new HttpClient(new URL(this.config.host), `/indexes/${this.config.index}`, this.config.apiKey);
    }

    async getResults<T>(params: URLSearchParams): Promise<null | MeilisearchResponse<T>> {
        params.append('facets', '*')
        params.append('limit', this.limit.toString())
        return await this.client.get<MeilisearchResponse<T>>('search', params)
    }

    async getAllFacets() {
        const searchParams = new URLSearchParams([
            ['facets', '*'],
            ['limit', '0'],
        ])

        return await this.client.get<MeilisearchResponse<any>>('search', searchParams);
    }
}
