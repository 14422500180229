import '@css/main.scss'
import * as Sentry from '@sentry/browser'
import Alpine from 'alpinejs'

import './_parts/quick-search'
import './archive/overview'
import './_utilities/alpine'
import './_parts/aria-expanded'
import './_parts/mailchimp'
import './_parts/nav'


Alpine.start()

if (import.meta.env.PROD) {
    Sentry.init({
        dsn: 'https://049254b943b011151f8560340a7c0f00@o4507408422993920.ingest.de.sentry.io/4507847918747728',

        release: `betonvereniging@${import.meta.env.VITE_COMMIT_HASH}`,
        integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
        tracesSampleRate: 0.2,

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
            'localhost',
            /^https:\/\/search\.test\.betonvereniging\.nl\.kiwicloud\.nl/,
            /^https:\/\/search\.accept\.betonvereniging\.nl\.kiwicloud\.nl/,
            /^https:\/\/search\.betonvereniging\.nl/,
        ],

        // Capture Replay for 2% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.02,
        replaysOnErrorSampleRate: 1.0,
    })
}

// Parts
const videos = document.querySelectorAll<HTMLElement>('.video__wrapper')
if (videos) {
    import('./_parts/video').then((module) => {
        new module.default(videos)
    })
}

const popup = document.getElementById('popup')
if (popup) {
    import('./_parts/popup').then((module) => {
        new module.default()
    })
}

// Blocks
const imagesSliders = document.querySelectorAll<HTMLElement>('.images-slider')
if (imagesSliders) {
    import('./_blocks/images').then((module) => {
        new module.default(imagesSliders)
    })
}

const reviewSliders = document.querySelectorAll<HTMLElement>('.review-slider')
if (reviewSliders) {
    import('./_blocks/reviews').then((module) => {
        new module.default(reviewSliders)
    })
}

const memberSlider = document.querySelectorAll<HTMLElement>('.member-slider')
if (memberSlider) {
    import('./_blocks/members').then((module) => {
        new module.default(memberSlider)
    })
}
