import { toggleAriaExpanded, toggleExpanded } from '../_utilities/aria'

const submenus = document.querySelectorAll<HTMLElement>('[data-nav-trigger]')
const mobileNav = document.getElementById('mobile-nav')
const mobileNavTrigger = document.getElementById('mobile-nav-trigger')
const mobileNavClose = document.getElementById('mobile-nav-close')
const mobileSearchTrigger = document.getElementById('mobile-search-trigger')
const overlay = document.getElementById('overlay')
const toggleContentAware = (e: Event) => {
    if (window.innerWidth > 1024) {
        if (
            !mobileNav?.contains(e.target as Node) &&
            !document.getElementById('nav-desktop')?.contains(e.target as Node)
        ) {
            if (document.documentElement.classList.contains('nav--active')) {
                document.documentElement.classList.remove('nav--active')
            }
        } else {
            if (!document.documentElement.classList.contains('nav--active')) {
                document.documentElement.classList.add('nav--active')
            }
        }
    }
}

const showNavDesktop = (e: Event) => {
    if (window.innerWidth > 1024) {
        document.documentElement.classList.add('nav--active')
    }
}

const disableNavAndRemoveFocus = (e: Event) => {
    if (window.innerWidth > 1024) {
        document.documentElement.classList.remove('nav--active');
        (document.activeElement as HTMLElement).blur()
    }
}

submenus.forEach((nav) => {
    nav.addEventListener('mouseenter', showNavDesktop)
    nav.addEventListener('focusin', toggleContentAware)
    nav.addEventListener('mouseleave', disableNavAndRemoveFocus)
    nav.addEventListener('focusout', disableNavAndRemoveFocus)
})

if (overlay) {
    overlay.addEventListener('click', () => {
        document.documentElement.classList.remove('nav--active')
        if (mobileNav?.dataset.expanded === 'true') {
            toggleExpanded(mobileNav, 'false')
            mobileNavTrigger?.setAttribute('aria-expanded', 'false')
            mobileSearchTrigger?.setAttribute('aria-expanded', 'false')
        }
    })
}

mobileNavTrigger?.addEventListener('click', () => {
    document.documentElement.classList.add('nav--active')
})

mobileSearchTrigger?.addEventListener('click', () => {
    document.documentElement.classList.add('nav--active')
    document.getElementById('mobile-nav-search-input')?.focus()
})

mobileNavClose?.addEventListener('click', () => {
    if (mobileNav) toggleExpanded(mobileNav, 'false')
    document.documentElement.classList.remove('nav--active')
    mobileNavTrigger?.setAttribute('aria-expanded', 'false')
    mobileSearchTrigger?.setAttribute('aria-expanded', 'false')
})

const closeAllMobileNavs = () => {
    mobileNav?.querySelectorAll('[aria-expanded="true"]').forEach((nav) => {
        toggleAriaExpanded(nav as HTMLElement)
    })
}

window.closeAllMobileNavs = closeAllMobileNavs
