export class HttpClient {
  private baseUri: URL;
  private searchIndex: string;
  private defaultHeaders: Record<string, string>;

  constructor(baseUri: URL, searchIndex: string = '/indexes/global', key: string) {
    this.defaultHeaders = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${key}`,
    };
    this.baseUri = baseUri;
    this.searchIndex = searchIndex;
  }

  async get<T>(path: string, searchParams?: URLSearchParams): Promise<T | null> {
    const url = new URL(this.baseUri.toString());
    url.pathname = `${this.searchIndex}/${path}`;

    if (searchParams) {
        searchParams.forEach((value, key) => {
            url.searchParams.append(key, value)
        })
    }

    try {
        const response = await fetch(url.toString(), { method: 'GET', headers: { ...this.defaultHeaders } });
        return await response.json();
    } catch (error: any) {
        console.error(error);
        return null;
    }
  }
}
