import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'
import focus from '@alpinejs/focus'

type Heading = {
    id: string
    index: number
}

Alpine.plugin(intersect)
Alpine.plugin(focus)

Alpine.store('headingsNavigator', {
    _activeHeadings: [],
    openHeading: '',

    get activeHeadingId() {
        const lastHeading = this._activeHeadings.sort(
            (a: Heading, b: Heading) => a.index - b.index
        )[this._activeHeadings.length - 1]

        return lastHeading?.id
    },

    addHeading(heading: Heading) {
        const headingIndex = this._activeHeadings.findIndex(
            (activeHeading) => activeHeading.id === heading.id
        )

        if (headingIndex === -1) {
            this._activeHeadings.push(heading)
        }
    },
    removeHeading(heading: Heading) {
        const headingIndex = this._activeHeadings.findIndex(
            (activeHeading) => activeHeading.id === heading.id
        )

        if (headingIndex !== -1) {
            this._activeHeadings.splice(1, headingIndex)
        }
    },
    toggleOpenHeading(headingId: string) {
        this.openHeading = this.openHeading === headingId ? '' : headingId
    },
})

window.Alpine = Alpine

Alpine.data('desktopNavigation', () => ({
    prevScrollPos: 0,
    scrollingDown: false,
    getScrollDir() {
        let goingDown = false
        let scrollPos = window.scrollY

        if (scrollPos > this.prevScrollPos && window.scrollY > 150) {
            goingDown = true
        }

        this.scrollingDown = goingDown
        this.prevScrollPos = scrollPos
    },
}))

Alpine.data('mobileNavigation', () => ({
    mobileMenuOpen: false,
    subNavOpen: false,
    init() {
        const mobileNav = document.getElementById('mobile-nav')

        if (mobileNav) {
            const observer = new MutationObserver((mutations) => {
                mutations.forEach((mutation) => {
                    if (mutation.type === 'attributes') {
                        this.mobileMenuOpen =
                            mobileNav.dataset.expanded === 'true'
                    }
                })
            })

            observer.observe(mobileNav, {
                attributes: true,
            })
        }
    },
}))

Alpine.data('mobileSubNav', () => ({
    menuOpened: false,
    init() {
        const subnav = this.$el.querySelector<HTMLElement>(
            '[data-mobile-subnav]'
        )

        if (subnav) {
            const observer = new MutationObserver((mutations) => {
                mutations.forEach((mutation) => {
                    if (mutation.type === 'attributes') {
                        if (mutation.attributeName === 'aria-hidden') {
                            if (subnav.ariaHidden === 'true') {
                                subnav.inert = true
                            } else {
                                subnav.inert = false
                            }
                        }
                    }
                })
            })

            observer.observe(subnav, {
                attributes: true,
            })
        }
    },
}))

