function getCurrentDay() {
    return new Date()
}

function getFirstDayOfCurrentWeek() {
    const date = new Date()
    const day = date.getDay()
    const diff = date.getDate() - 7
    return new Date(date.setDate(diff))
}

function getFirstDayOfCurrentMonth() {
    const date = new Date()
    const day = date.getDay()
    const numberOfDays = (y, m) => new Date(y, m, 0).getDate();
    const days = numberOfDays(date.getFullYear(), date.getMonth())
    const diff = date.getDate() - days

    return new Date(date.setDate(diff))
}

function getFirstDayOfCurrentYear() {
    const date = new Date()
    const day = date.getDay()
    const diff = date.getDate() - 365

    return new Date(date.setDate(diff))
}

export function getTimeStamps() {
    return {
        timestampWeekStart: getFirstDayOfCurrentWeek().getTime(),
        timestampWeekEnd:  getCurrentDay().getTime(),
        timestampMonthStart: getFirstDayOfCurrentMonth().getTime(),
        timestampMonthEnd: getCurrentDay().getTime(),
        timestampYearStart: getFirstDayOfCurrentYear().getTime(),
        timestampYearEnd: getCurrentDay().getTime()
    }
}
