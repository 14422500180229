import Alpine from 'alpinejs'

type MailchimpFormInput = {
    CRAFT_CSRF_TOKEN: string;
    firstname: string;
    lastname: string;
    email: string;
    areaOfInterest: string;
}

type MailchimpResponse = {
    success: boolean;
    msg: string
}

Alpine.data('mailchimp', () => ({
    response: false,

    data(): MailchimpFormInput {
        const inputs = Array.from(this.$el.querySelectorAll<HTMLInputElement>("input, textarea, select"));
        const data = inputs.reduce(
            (object, key) => ({ ...object, [key.name]: key.value }),
            {}
        );
        return data as MailchimpFormInput;
    },

    async post() {
        const data  = this.data();
        const formData = new FormData();
        formData.append("name", data.firstname + " " + data.lastname);
        formData.append("email", data.email);
        formData.append("tags", data.areaOfInterest);

        const response = await (
            await fetch("/mailchimp/send", {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "X-CSRF-Token": data.CRAFT_CSRF_TOKEN,
                    "X-Requested-With": "XMLHttpRequest",
                },
                body: formData,
            })
        ).json() as MailchimpResponse;

        if (response.success) {
            this.reset();
            // @ts-ignore
            return this.response = {
                success: true,
                msg: "Je bent aangemeld voor de nieuwsbrief."
            };
        }

        if(response.success === false && response.msg.includes("Member Exists")) {
            this.reset();
            // @ts-ignore
            return this.response = {
                success: true,
                msg: "Je bent al aangemeld voor deze nieuwsbrief."
            }
        }

        // @ts-ignore
        return this.response = {
            success: false,
            msg: "Aanmelding mislukt. Probeer het later nog eens."
        }
    },

    reset() {
        (this.$refs.form as HTMLFormElement).reset();
        setTimeout(() => {
            // @ts-ignore
            this.response = false;
        }, 5000);
    }
}));
