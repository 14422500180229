import {toggleAriaExpanded} from '../_utilities/aria'

const toggles = document.querySelectorAll(
    '[aria-controls][aria-expanded]:not([data-accordion-trigger]):not([data-ignore])'
)

toggles.forEach((toggle) => {
    toggle.addEventListener('click', () => {
        toggleAriaExpanded(toggle as HTMLElement)
    })
})
