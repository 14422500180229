import Alpine from 'alpinejs'
import MeilisearchClient from '@modules/meilisearch';
import {
    getTimeStamps
} from '../_utilities/dateHelper';

import type { FilterOption, Facet, FacetValue, FacetDistribution, GetResultsType, MeilisearchResponse } from '@utils/types/search';
import type { Edition } from '@utils/types/edition';
import type { Translations } from '@utils/types/translations';
import { isInViewport } from '@utils/viewport';

declare module 'alpinejs' {
    interface Stores {
        sectionOverview: {
            client: MeilisearchClient | null
            hits: any[]
            query: string
            params: string[]
            sort: string
            isLoading: boolean
            hasError: boolean
            hasMoreResults: boolean
            estimatedTotalHits: number
            offset: number
            filtersExpanded: boolean
            $refs?: any,
            filters: FilterOption[]
            isFilterActive: () => boolean
            filterStartDate: number | null
            filterEndDate: number | null
            availableFacets: Facet[]
            attributesToRetrieve: string[]
            resetStartDate: () => void
            resetEndDate: () => void
            translations: Translations
            setFilterDate: (value: string, isStartDate: boolean) => void
            initialize: () => void
            getFilterSearchParams: () => string
            getTranslation: (key: string) => string | null
            getAllFacets: () => Promise<void>
            getFacetValues: (key: string) => FacetValue[]
            buildFacetDistribution: (data: FacetDistribution) => void
            toggleMobileFilters: () => void
            hasNoResults: () => boolean
            toggleFilter: (filter: FilterOption) => void
            countEditionLocations: (editions: Edition[]) => number
            resetAllFilters: () => void
            getResults: (
                query: string,
                offset?: number
            ) => Promise<void | GetResultsType<any>>
            getMoreResults: () => Promise<void>
        }
    }
}

enum FacetKey {
    CATEGORY = 'section.name',
    POST_DATE = 'postDate_timestamp',
    AREA_OF_INTEREST = 'areasOfInterest.title',
    LOCATION = 'planningSection.editions.location.address.province',
    START_DATE = 'planningSection.editions.startDate_timestamp',
    COURSE_LEVEL = 'filterSection.courseLevel',
    INTAKE_LEVEL = 'filterSection.intakeLevel',
    KNOWLEDGE_UNITS = 'filterSection.knowledgeUnits',
    NUMBER_OF_CLASSES = 'filterSection.numberOfClasses',
    SECTOR = 'filterSection.sectors.sectorName',
    STUDYLOAD = 'filterSection.studyLoad',
}

const translations = {
    [FacetKey.AREA_OF_INTEREST]: 'Domein',
    [FacetKey.POST_DATE]: 'Publicatiedatum',
    [FacetKey.LOCATION]: 'Locatie',
    [FacetKey.START_DATE]: 'Startdatum',
    [FacetKey.CATEGORY]: 'Categorie',
    [FacetKey.COURSE_LEVEL]: 'Niveau van de cursus',
    [FacetKey.INTAKE_LEVEL]: 'Jouw vooropleiding',
    [FacetKey.KNOWLEDGE_UNITS]: 'Kenniseenheden',
    [FacetKey.NUMBER_OF_CLASSES]: 'Aantal bijeenkomsten',
    [FacetKey.SECTOR]: 'Jouw sector',
    [FacetKey.STUDYLOAD]: 'Studielast',
}

const courseLevelSortOrder = [
    'geen voorkennis vereist',
    'vmbo',
    'mbo',
    'mbo+',
    'hbo',
    'hbo+',
    'wo',
]

const knowledgeUnitsSortOrder = ['1 tot 3', '4 tot 6', '7 tot 9', '10 of meer']

const studyLoadSortOrder = [
    'tot 20 uur',
    '21 tot 50 uur',
    '51 tot 100 uur',
    'meer dan 100 uur',
]

enum PublicationDateOrder {
    WEEK = 'Afgelopen week',
    MONTH = 'Afgelopen maand',
    YEAR = 'Afgelopen jaar',
    OLDER = 'Ouder dan een jaar'
}

const publicationDateOrderSortOrder: string[] = [
    PublicationDateOrder.MONTH,
    PublicationDateOrder.WEEK,
    PublicationDateOrder.YEAR,
    PublicationDateOrder.OLDER
];

const facetsOrder: string[] = [
    FacetKey.POST_DATE,
    FacetKey.AREA_OF_INTEREST,
    FacetKey.SECTOR,
    FacetKey.INTAKE_LEVEL,
    FacetKey.COURSE_LEVEL,
    FacetKey.NUMBER_OF_CLASSES,
    FacetKey.STUDYLOAD,
    FacetKey.KNOWLEDGE_UNITS,
    FacetKey.LOCATION,
    FacetKey.START_DATE,
]

/**
 * Sort the facets by the order in the facetsOrder array
 */
const sortFacets = (a: Facet, b: Facet) => {
    return (
        facetsOrder.indexOf(a.key) - facetsOrder.indexOf(b.key) ||
        a.key.localeCompare(b.key)
    )
}

const getFacetType = (name: string): 'default' | 'dateRange' => {
    if (name === 'planningSection.editions.startDate_timestamp') {
        return 'dateRange'
    }

    return 'default'
}

const sortValues = (values: FacetValue[], key: string) => {
    switch (key) {
        case FacetKey.COURSE_LEVEL:
            return values.sort((a, b) => {
                return (
                    courseLevelSortOrder.indexOf(a.name.toLowerCase()) -
                    courseLevelSortOrder.indexOf(b.name.toLowerCase())
                )
            })
        case FacetKey.KNOWLEDGE_UNITS:
            return values.sort((a, b) => {
                return (
                    knowledgeUnitsSortOrder.indexOf(a.name.toLowerCase()) -
                    knowledgeUnitsSortOrder.indexOf(b.name.toLowerCase())
                )
            })
        case FacetKey.STUDYLOAD:
            return values.sort((a, b) => {
                return (
                    studyLoadSortOrder.indexOf(a.name.toLowerCase()) -
                    studyLoadSortOrder.indexOf(b.name.toLowerCase())
                )
            })
        case FacetKey.POST_DATE:
            return values.sort((a, b) => {
                return (
                    publicationDateOrderSortOrder.indexOf(a.name.toLowerCase()) -
                    publicationDateOrderSortOrder.indexOf(b.name.toLowerCase())
                )
            })
        default:
            return values
    }
}

const attributesToRetrieve = [
    'title',
    'uid',
    'url',
    'excerpt',
]

function resetStartDate() {
    const startDateFilterInput = document.getElementById(
        'startDateFilter'
    ) as HTMLInputElement
    if (startDateFilterInput) {
        startDateFilterInput.value = ''
    }
}

function resetEndDate() {
    const endDateFilterInput = document.getElementById(
        'endDateFilter'
    ) as HTMLInputElement
    if (endDateFilterInput) {
        endDateFilterInput.value = ''
    }
}

function getPublicationFacetValue(timestamp: string) {
    timestamp = parseInt(timestamp);
    const {
        timestampWeekStart,
        timestampWeekEnd,
        timestampMonthStart,
        timestampMonthEnd,
        timestampYearStart,
        timestampYearEnd
    } = getTimeStamps();

    if (timestamp > timestampWeekStart && timestamp < timestampWeekEnd) {
        return PublicationDateOrder.WEEK
    } else if (timestamp > timestampMonthStart && timestamp < timestampMonthEnd) {
        return PublicationDateOrder.MONTH
    } else if (timestamp > timestampYearStart && timestamp < timestampYearEnd) {
        return PublicationDateOrder.YEAR
    } else if (timestamp < timestampYearEnd) {
        return PublicationDateOrder.OLDER
    }
}

function getPublicationFilterValues(key: string, values: string[]) {
    const {
        timestampWeekStart,
        timestampWeekEnd,
        timestampMonthStart,
        timestampMonthEnd,
        timestampYearStart,
        timestampYearEnd
    } = getTimeStamps();

    return values.map((value) => {
        switch (value.replaceAll('"', '')) {
            case PublicationDateOrder.WEEK:
                return `(${key} >= ${timestampWeekStart} AND ${key} <= ${timestampWeekEnd})`
            case PublicationDateOrder.MONTH:
                return `(${key} >= ${timestampMonthStart} AND ${key} <= ${timestampMonthEnd})`
            case PublicationDateOrder.YEAR:
                return `(${key} >= ${timestampYearStart} AND ${key} <= ${timestampYearEnd})`
            case PublicationDateOrder.OLDER:
                return `(${key} <= ${timestampYearStart})`
        }
    }).join(' OR ');
}

Alpine.store('sectionOverview', {
    client: null,
    isLoading: false,
    hasError: false,
    hasMoreResults: false,
    offset: 0,
    estimatedTotalHits: 0,
    query: '',
    params: [],
    sort: '',
    hits: [],
    filtersExpanded: false,
    filters: [],
    filterStartDate: null,
    filterEndDate: null,
    attributesToRetrieve,
    availableFacets: [],
    translations,
    initialize() {
        this.setFiltersFromParams()
        Promise.all([this.getResults(this.query), this.getAllFacets()])
        Alpine.effect(() => {
            if (this.filterStartDate || this.filterEndDate) {
                this.getResults(this.query)
            }
        })
    },
    handleLabels(e) {
        setTimeout(() => {
            const observer = new ResizeObserver(entries => {
                for (let entry of entries) {
                    const labelsWrapper = e.querySelector('.labels-wrapper');
                    const labels = Array.from(labelsWrapper.querySelectorAll(':scope > .label'));
                    const tooltipButton = e.querySelector('.tooltip-button');
                    const tooltipButtonText = tooltipButton.querySelector('.tooltip-count');
                    const tooltipLabels = Array.from(tooltipButton.querySelectorAll('.label'));
                    // if screen is mobile skip the logic;
                    if (window.innerWidth < 768) {
                        // reset all classes
                        labels.forEach(label => {
                            label.classList.remove('hidden');
                        });
                        tooltipLabels.forEach(label => {
                            label.classList.add('hidden');
                        }
                        );

                        tooltipButton.classList.add('hidden');
                    } else {
                        const labelsWrapperWidth = labelsWrapper.offsetWidth - 44;
                        let totalWidth = 0;
                        let hiddenCount = 0;

                        labels.forEach(label => {
                            label.classList.remove('hidden');
                        });

                        tooltipLabels.forEach(label => {
                            label.classList.add('hidden');
                        });

                        labels.forEach(label => {
                            totalWidth += label.offsetWidth + 8;
                            if (totalWidth > labelsWrapperWidth) {
                                label.classList.add('hidden');
                                tooltipLabels.find((x) => x.innerText === label.innerText)?.classList.remove('hidden');
                                hiddenCount++;
                            }
                        });

                        if (hiddenCount > 0) {
                            tooltipButton.classList.remove('hidden');
                            tooltipButtonText.textContent = `+${hiddenCount}`;
                        } else {
                            tooltipButton.classList.add('hidden');
                        }
                    }
                }
            });

            observer.observe(e);
        }, 50);
    },
    setFiltersFromParams() {
        if (this.params.length === 0 || this.params.filter == null) return

        Object.keys(this.params.filter).forEach((key) => {
            const value = this.params.filter[key]
            if (Array.isArray(value)) {
                value.forEach((item) => {
                    this.toggleFilter({
                        key,
                        type: getFacetType(key),
                        value: item.replaceAll('"', ''),
                    }, false)
                })
            } else {
                this.toggleFilter({
                    key,
                    type: getFacetType(key),
                    value: value.replaceAll('"', ''),
                }, false)
            }
        })

        this.getResults(this.query);
    },
    countEditionLocations(editions) {
        return new Set(editions.flatMap((i) => i.location?.[0]?.title ?? []))
            .size
    },
    resetStartDate() {
        resetStartDate()
        this.filterStartDate = null
        this.getResults(this.query)
    },
    resetEndDate() {
        resetEndDate()
        this.filterEndDate = null
        this.getResults(this.query)
    },
    setFilterDate(value: string, isStartDate: boolean) {
        if (!Number.isNaN(Date.parse(value))) {
            this[isStartDate ? 'filterStartDate' : 'filterEndDate'] =
                Math.floor(Date.parse(value) / 1000)
        } else {
            this[isStartDate ? 'filterStartDate' : 'filterEndDate'] = null
        }

        this.getResults(this.query)
    },
    async getAllFacets() {
        const data = await this.client!.getAllFacets()

        if (data !== null) {
            this.buildFacetDistribution(data.facetDistribution)
        }
    },
    buildFacetDistribution(data: FacetDistribution) {
        const facets: Facet[] = Object.entries(data).flatMap(([key, value]) => {
            const label = this.getTranslation(key)
            if (label === null) return []
            var uniqueValues = [];

            return {
                key,
                label,
                type: getFacetType(key),
                values: sortValues(
                    Object.entries(value).map(([name]) => ({
                        name: key === FacetKey.POST_DATE ? getPublicationFacetValue(name) : name
                    })),
                    key
                ).filter((item) => {
                    if (uniqueValues.includes(item.name)) {
                        return null;
                    }
                    uniqueValues.push(item.name);
                    return item;
                })
            }
        })

        if (this.availableFacets.length === 0) {
            this.availableFacets = facets.sort(sortFacets).flatMap((facet) => {
                if (facet.values.length === 0) return []
                return facet
            })

            return
        }
    },
    toggleMobileFilters() {
        this.filtersExpanded = !this.filtersExpanded
    },
    getTranslation(key: string): string | null {
        if (this.translations[key] === undefined) {
            console.warn(`No translation found for ${key}`)
        }
        return this.translations[key] || null
    },
    hasNoResults() {
        return this.hits.length === 0
    },
    getFacetValues(key: string) {
        return (
            this.availableFacets.find((f: Facet) => f.key === key)?.values ?? []
        )
    },
    isFilterActive(key: string, value: string) {
        return this.filters.some(
            (f: FilterOption) => f.key === key && f.value === value
        )
    },
    resetAllFilters() {
        const searchInput = document.getElementById(
            'overview-search'
        )! as HTMLInputElement

        this.filterStartDate = null
        this.filterEndDate = null

        const activeFilters = document
            .getElementById('filter-list')!
            .querySelectorAll(
                '.filter-checkbox:checked'
            )! as NodeListOf<HTMLInputElement>

        if (activeFilters) {
            activeFilters.forEach((filter) => {
                filter.checked = false
            })
        }

        this.filters = []
        this.query = ''
        searchInput.value = ''
        resetStartDate()
        resetEndDate()
        this.getResults(this.query)
        isInViewport(searchInput) && searchInput.focus()
    },
    toggleFilter({ key, value }: FilterOption, getResults: boolean = true) {
        const filterIndex = this.filters.findIndex(
            (f: FilterOption) => f.key === key && f.value === value
        )
        if (filterIndex !== -1) {
            this.filters.splice(filterIndex, 1)
        } else {
            this.filters.push({
                key,
                type: getFacetType(key),
                value,
            })
        }

        if (getResults) {
            this.getResults(this.query)
        }
    },
    getFilterSearchParams(): string {
        const groupedFilters = this.filters.reduce((acc, { key, value }) => {
            acc[key] = [...(acc[key] || []), `"${value}"`]
            return acc
        }, {} as { [key: string]: string[] })

        const dateFilter = () => {
            if (!this.filterStartDate && !this.filterEndDate) return ''

            const startDate = this.filterStartDate
                ? ` >= ${this.filterStartDate}`
                : undefined
            const endDate = this.filterEndDate
                ? ` <= ${this.filterEndDate}`
                : undefined

            return [startDate, endDate]
                .flatMap((value) => {
                    if (!value) return []
                    return FacetKey.START_DATE + value
                })
                .join(' AND ')
        }

        const groupedFiltersValues =
            Object.entries(groupedFilters).length > 0
                ? Object.entries(groupedFilters)
                    .map(
                        ([key, values]) =>
                            key === FacetKey.POST_DATE
                                ? getPublicationFilterValues(key, values)
                                : `(${key}=${values.join(` OR ${key}=`)})`
                    )
                    .join(' AND ')
                : undefined

        return [groupedFiltersValues, dateFilter()]
            .filter((e) => e)
            .join(' AND ')
    },
    async getResults(query, offset = 0) {
        if (!this.client) {
            return console.error('No client found');
        }
        this.query = query

        this.hasError = false;
        const loadingTimeoutId = setTimeout(() => {
            this.isLoading = true
        }, 500)

        if (offset === 0) {
            this.offset = 0
        }

        const params = new URLSearchParams({
            q: query,
            offset: offset.toString(),
            attributesToRetrieve: this.attributesToRetrieve.join(','),
        });

        if (
            this.filters.length > 0 ||
            this.filterStartDate ||
            this.filterEndDate
        ) {
            const filterParams = this.getFilterSearchParams()
            params.append('filter', filterParams)
        }

        params.append('sort', this.sort);

        let data: MeilisearchResponse<any> | null = null;

        if (this.client) {
            data = await this.client.getResults<any>(params);
        }

        clearTimeout(loadingTimeoutId)

        if (!data) {
            this.isLoading = false;
            this.hasError = true;
            return console.error(
                'Something went wrong while fetching the results'
            )
        }

        this.hasMoreResults =
            (data.hits?.length ?? 0) + offset < data.estimatedTotalHits

        if (offset > 0) {
            return {
                hits: data.hits || [],
                estimatedTotalHits: data.estimatedTotalHits,
            }
        } else {
            this.isLoading = false
            this.hits = data.hits || []
            this.estimatedTotalHits = data.estimatedTotalHits
            return {
                hits: data.hits || [],
                estimatedTotalHits: data.estimatedTotalHits,
            }
        }
    },
    async getMoreResults() {
        this.isLoading = true
        const [results] = await Promise.all([
            this.getResults(this.query, (this.offset += 10)),
            new Promise((resolve) => setTimeout(resolve, 600)), // artificial delay
        ])
        this.hits = [...this.hits, ...(results?.hits ?? [])]
        this.estimatedTotalHits = results?.estimatedTotalHits || 0
        this.isLoading = false
    },
})

document.addEventListener('alpine:init', () => {
    // @ts-ignore
    Alpine.data('sectionOverview', (data: any) => {
        Alpine.store('sectionOverview').client = new MeilisearchClient({
            host: data.host,
            apiKey: data.key,
            index: data.searchIndex,
        }, 10)

        Alpine.store('sectionOverview').sort = data.sort || 'title:asc'

        if (data.query) {
            Alpine.store('sectionOverview').query = data.query
        }
        if (data.params) {
            Alpine.store('sectionOverview').params = data.params
            if (data.params.query) {
                Alpine.store('sectionOverview').query += data.params.query
            }
            if (data.params.q) {
                Alpine.store('sectionOverview').query += data.params.q
            }
        }
        if (data.hits) {
            Alpine.store('sectionOverview').hits = data.hits
        }
        if (data.estimatedTotalHits) {
            Alpine.store('sectionOverview').estimatedTotalHits = data.estimatedTotalHits
        }
        if (data.facetDistribution) {
            Alpine.store('sectionOverview').buildFacetDistribution(data.facetDistribution)
        }
        if (data.attributes) {
            const combinedAttributes = attributesToRetrieve.concat(data.attributes)
            Alpine.store('sectionOverview').attributesToRetrieve = [...new Set(combinedAttributes)]
        }

        Alpine.store('sectionOverview').initialize()
    })
})
