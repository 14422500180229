import Alpine from 'alpinejs'
import MeilisearchClient from '@modules/meilisearch';

import type { GetResultsType, MeilisearchResponse } from '@utils/types/search';

declare module 'alpinejs' {
    interface Stores {
        quickSearch: {
            client: MeilisearchClient | null
            open: boolean
            hits: any[]
            query: string
            isLoading: boolean
            attributesToRetrieve: string[]
            initialize: () => void
            hasNoResults: () => boolean
            getResults: (
                query: string,
                offset?: number
            ) => Promise<void | GetResultsType<any>>
        }
    }
}

const attributesToRetrieve = [
    'title',
    'uid',
    'url',
]

Alpine.store('quickSearch', {
    client: null,
    open: false,
    hits: [],
    query: '',
    isLoading: false,
    attributesToRetrieve,
    initialize() {
        if(!this.client) {
            return console.error('No client found');
        }
    },
    hasNoResults() {
        return this.hits.length === 0
    },
    async getResults(query) {
        this.query = query

        const loadingTimeoutId = setTimeout(() => {
            this.isLoading = true
        }, 500)


        const params = new URLSearchParams({
            q: query,
            attributesToRetrieve: this.attributesToRetrieve.join(','),
        });

        let data: MeilisearchResponse<any> | null = null;

        if (this.client) {
            data = await this.client.getResults<any>(params);
        }

        clearTimeout(loadingTimeoutId)

        if (!data) {
            this.isLoading = false;
            return console.error(
                'Something went wrong while fetching the results'
            )
        }

        this.isLoading = false
        this.hits = data.hits || []
    },
})

document.addEventListener('alpine:init', () => {
    // @ts-ignore
    Alpine.data('quickSearch', (data: any) => {
        Alpine.store('quickSearch').client = new MeilisearchClient({
            host: data.host,
            apiKey: data.key,
            index: data.searchIndex,
        }, 4)

        if (data.query) {
            Alpine.store('quickSearch').query = data.query
        }

        if (data.attributes) {
            const combinedAttributes = attributesToRetrieve.concat(data.attributes)
            Alpine.store('quickSearch').attributesToRetrieve = [...new Set(combinedAttributes)]
        }

        Alpine.store('quickSearch').initialize()
    })
})
